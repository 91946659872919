@mixin center {
    width: 5em;
    height: 5em;
    line-height: 7em;
    text-align: center;
    display: inline-block;
}

.page {
    .headers {
        height: 100%;
    }

    .theme {
        margin: 2em 0 0 0;
    }
    
    .headers {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-items: center;
        margin-bottom: 2em;
    }

    .buttons {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-items: center;

        .button-grid {
            display: flex;
            flex-direction: column;
            max-width: 15em;
        }
    }

    .inputs {
        input {
            width: 100%;
        }
        input[type="checkbox"], input[type="radio"]{
            width: auto;
        }
    }
    
    .square {
        display: grid;
        grid-template-columns: auto auto;
        justify-content: space-evenly;
    }
    
    .backgrounds {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        height: 19vh;
        .background1 {
            @include center;
            background-color: var(--background-primary);
            color: var(--text-primary);
            border: 0.05em dashed var(--text-primary);
        }
        
        .background2 {
            @include center;
            background-color: var(--background-secondary);
            color: var(--text-secondary);
            border: 0.05em dashed var(--text-secondary);
        }
        
        .background3 {
            @include center;
            background-color: var(--background-tertiary);
            color: var(--text-tertiary);
            border: 0.05em dashed var(--text-tertiary);
        }
    }

    .data-export {
        margin-top: 1em;
        flex-direction: column;
        gap: 1em;

        select {
            width: 50%;
            margin-left: 1em;
        }
    }
}




