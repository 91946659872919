@import '../../shared/layout/components/variables';
.practitioner {
    .practitioner-details {
        display: flex;
        align-items: center;
        flex-direction: column;

        .profile {
            height: 5.5em;
            width: 5.5em;
            background-color: var(--color-yellow);
            border: 0.3rem solid var(--color-yellow);
            border-radius: 4em;
        }
    }

    .buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1em;

        .button {
            width: calc(100% - 6em);
            text-align: center;
        }
    }
}

@media screen and (min-width: $minimum-viewport) {
    .practitioner {
        .practitioner-details {
            margin-top: 5em;
        }

        .buttons {
            .button {
                width: 16em;
            }
        }
    }
}

@media screen and (max-width: $minimum-viewport) {
    .practitioner {    
        .buttons {    
            .button {
                padding: 1em 2.5em;
            }
        }
    }
}
