@import "../../shared/layout/components/variables";

.mystuff {
    .bar {
        display: flex;
        align-items: center;
        flex-direction: column;

        select {
            align-self: flex-start;
        }

        .buttons {
            display: flex;
            position: initial;
            flex-direction: row;
            width: 100%;
            justify-content: space-evenly;
            gap: 0.5em;

            button {
                margin: 0.65em 0;
                width: 50%;
                height: 3.6em;
                justify-content: center;

            }

            .button-add,
            .button-cancel {
                svg {
                    fill: var(--color-black);
                }
            }
        }
    }

    @media screen and (min-width: $minimum-viewport) {
        .bar {
            flex-direction: row;

            select {
                align-self: auto;
                margin-right: 0.8em;
            }

        }
    }

    .grid {
        height: calc(100vh - 20.5em) !important;
        overflow-y: scroll;

        @media screen and (min-width: $minimum-viewport) {
            height: 68.4vh !important;
        }

        &-container {
            display: grid;
            gap: 0.2em;
            grid-template-columns: repeat(2, 1fr);
            grid-auto-flow: dense;

            @media screen and (min-width: $minimum-viewport) {
                grid-template-columns: repeat(3, 1fr);
            }

            label {
                width: auto;
                display: inline;
                padding-top: 0;

                .image {
                    border-radius: 1em;
                }
            }
        }

        &-item {
            border-radius: 1em;
            display: table;
            position: relative;
        }

        .controlElement {
            position: absolute;
            top: 0em;
            left: 0.5em;
            height: 2em;
            opacity: 1;
        }

        .selected {
            border: 2px solid var(--color-red);
        }

        .text,
        .emoji {
            background-color: var(--color-purple);
            color: white;
            font-size: large;
            font-weight: bold;
            aspect-ratio: 1.33;
            grid-row: span 3;
            text-align: center;

            span {
                display: table-cell;
                vertical-align: middle;
                padding: 1em;
                text-overflow: clip;
                max-width: 5em;
                overflow: hidden;
            }
        }

        .text {
            width: 100%;
        }

        .emoji {
            background-color: var(--grid-emoji-background);
            display: grid;
            align-items: center;
            font-size: 1.3em;

            ul {
                display: flex;
                justify-content: space-evenly;
                line-height: 1;
            }

            li {
                margin: 0;
            }

            span {
                padding: 0;
            }

            &-1 {
                font-size: 3em;
            }

            .controlElement {
                top: -0.5em;
            }
        }

        ;

        .image {
            aspect-ratio: 0.65;
            grid-row: span 6;
            background-size: cover;

            img {
                width: 100%;
            }
        }

        .link {
            background-color: var(--color-blue-light);
            aspect-ratio: 1;
            grid-row: span 4;
            text-align: center;

            span {
                color: var(--color-black);
                text-align: left;
                text-decoration: underline;
                display: inline-block;
                width: 35vw;
                overflow-wrap: break-word;
                font-size: 1em;
                margin: 0.5em;

                @media screen and (min-width: $minimum-viewport) {
                    width: 7.3em;
                }
            }
        }

        .link,
        .image {
            .title {
                font-size: 0.7em;
                margin: 0.5em;
                padding: 0 1em;
                vertical-align: top;
                background-color: var(--color-black);
                color: var(--color-white);
                border-radius: 1em;
                position: relative;
                text-align: left;
                float: left;
            }
        }
    }
}

.link {
    &-add {
        label {
            display: inline-block;
            margin-bottom: 0.5em;
        }
    }
}

.text {
    label {
        display: inline-block;
        margin-bottom: 0.5em;
    }

    textarea {
        width: 100%;
        height: 10em;
    }
}

.emoji {
    &-add {
        .input-container {
            display: grid;
            grid-template-columns: 75% 25%;
            align-items: center;

            .list-background {
                display: grid;
                grid-template-rows: auto;
                align-items: end;
                height: 1.5em;
                border: 0.1em solid var(--color-light-grey-dark);
                border-radius: 0.75em;
            }

            .error {
                border-color: var(--error);
            }
        }

        .error-message {
            color: var(--error);
            font-weight: bold;
            grid-column: 1/3;
            height: 1.5em;
        }

        .emoji-picker {
            display: flex;
            justify-content: center;
            position: relative;
            border-radius: 1em;
            z-index: 0;
        }

        @media screen and (min-width: 426px) {
            .emoji-picker {
                padding: 0.75em 0;
                background: var(--action-secondary);
            }
        }
    }
}

.upload {
    .preview {
        display: flex;
        margin-top: 1em;
        gap: 1em;

        &-full {
            width: 100%;
            border-radius: 1em;
            background-size: cover;
        }
    }

    input[type="file"] {
        height: 0;
        width: 0;
        overflow: hidden;
        white-space: nowrap;
        position: absolute;
        display: none;
    }

    input[type="textbox"] {
        margin: 0;
    }

    .button-upload {
        width: 50vw;
        text-align: center;
    }

    .loader {
        height: 60vh;
    }


    .buttons {
        display: flex;
        justify-content: center;
        gap: 1em;
    }

    @media screen and (max-width: $minimum-viewport) {
        .buttons {
            button {
                padding: 1em 2.5em;
            }
        }
    }
}

.emoji-list {
    display: flex;
    justify-content: center;

    li {
        position: relative;
        margin: 0 0.75rem 0 0;

        svg {
            fill: var(--text-primary);
            position: absolute;
            height: 0.7em;
            width: 0.7em;
            top: -0.4rem;
            right: -0.6rem;
            z-index: 1;
        }
    }
}

.mystuff-addpage {
    .stuff-add {
        margin: 0 0 1em 1em;

        &-type {
            margin: 1em 0 1em 1em;
        }
    }

    a {
        text-decoration: underline;
    }
}