@font-face{
    font-family: Manrope;
    font-weight: 400;
    src: url("/shared/fonts/Manrope-Regular.woff2")
}

@font-face{
    font-family: Manrope;
    font-weight: 700;
    src: url("/shared/fonts/Manrope-Bold.woff2")
}

@font-face{
    font-family: Manrope;
    font-weight: 900;
    src: url("/shared/fonts/Manrope-ExtraBold.woff2")
}


@mixin bold {
    font:bold;
    font-weight: 700;
}