@use '../../shared/settings/Settings.scss';
$profile-picture-size: 7rem;
$profile-picture-border: 0.2rem;

.settings{
    ul{
        list-style: none;
        padding: 0;
    }
}

.profile {
    pre{
        font-family: manrope;
        min-height: 5em;
        margin: 0;
        padding: 0.1em;
    }

    .picture {
        &-container {
            display: flex;
        }
        .img {
            display: inline-block;
            border-radius: 5em;
            width: $profile-picture-size;
            height: $profile-picture-size;
            background-color: var(--color-yellow);
            margin-right: 1em;
            background-size: cover;
            border: $profile-picture-border solid var(--color-yellow);
        }
        &-file {
            .button,button{
                padding-left: 2em;
                padding-right: 2em;
                width: auto;
            }
            input[type="file"] {
                border: 0;
                height: 0;
                overflow: hidden;
                padding: 0;
                white-space: nowrap;
                width: 0;
                position: absolute;
            }
        }
        .delete {
            font-size: 0.75em;
            margin-left: 1em;
            text-decoration: underline;
            cursor: pointer;
        }

        .ReactCrop {
            img {
                width: 100%;
            }
        }
    }

    form {
        .button-container {
            margin-top: 1em;
        }
    }
}

.backgrounds {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 0.8em;
    justify-items: center;

    .item {
        width: 5rem;
        height: 5rem;
        font-size: 0;
        background-color: var(--background-tertiary);
        background-position: center;
        background-size: cover;
        border: $profile-picture-border solid var(--action-text-primary);

        &-selected {
            border: $profile-picture-border solid var(--color-purple);
        }
    }
}
