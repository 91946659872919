@import '_variables';

body {
    background-color: var(--background-primary);
    font-family: Manrope;
    font-weight: 400;
    margin: 0;
    padding: 0;
    font-size: 1.2rem;
    color: var(--text-primary);
}

textarea, input[type="date"], input[type="time"] {
    font-family: Manrope;
}

h1 {
    font-weight: 900;
    font-size: 3.2rem;
    line-height: 1em;
}

@media screen and (max-width: $smallest-viewport) {
    h1 {
        font-size: 2.9rem;
    }
}

h2 {
    font-size: 2rem;
}

h3,
h4,
h5,
h6 {
    font-weight: 700;
    font-size: 1.7rem;
}
a, a:visited {
    text-decoration: none;
}
a {
    color: var(--text-primary);
}

button,
.button {
    font-size: 1rem;
    font-weight: bold;
    font-family: Manrope;
    text-decoration: none;
    border-radius: var(--button-styling-round);
    padding: 1em 5em 1em 5em;
    margin: 0.25em 0.25em;
    background-color: var(--color-yellow);
    color: var(--color-black);
    border: none;
    display: inline-block;
    cursor: pointer;

    &-secondary {
        background-color: var(--action-secondary);
        color: var(--action-text-primary);
    }

    &-tertiary {
        background-color: var(--color-purple);
        color: var(--color-white);
    }

    &-delete {
        background-color: var(--delete-fill);
        color: var(--color-white);
    }

    &-square {
        border-radius: var(--button-styling-square);
        padding: 1em 1em;
        margin: 0.5em 0.5em;

        &-primary {
            background-color: var(--button-square-primary);
            color: var(--button-square-text-primary);
        }

        &-secondary {
            background-color: var(--button-square-secondary);
            color: var(--button-square-text-secondary);
        }

        &-icon {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            width: initial;
            gap: 0.4em;
        }

        &-icon-max {
            justify-content: center;
        }

    }

    &-max {
        width: 100%;
    }

    &-small {
        padding: 1em 1em;
    }

    &-minimum {
        padding: 0.5em 0.5em;
    }
}

input, textarea {
    box-sizing: border-box;
    font-size: 1.5rem;
    width: 100%;
    padding: 0.3rem;
}

input[type="checkbox"], input[type="radio"]{
    width: 1em;
}

input:read-only {
    background-color: var(--read-only);
}

label {
    width: 100%;
    font-weight: bold;
    display: inline-block;
    &:not(:first-child){
        padding-top: 0.5em;
    }
}
.no-padding {
    padding-top: 0 !important;
}

select {
    border-radius: 1em;
    padding: 1em;
    height: 4em;
    background: var(--background-primary);
    border-color: var(--text-primary);
    font-family: Manrope;
    font-weight: bold;

    option {
        font-family: Manrope;
        font-weight: bold;
        background-color: var(--background-primary);
        &::hover{
            background-color: var(--color-yellow);
        }
        &::active{
            background-color: var(--color-yellow);
        }
    }
}

@media screen and (max-width: $minimum-viewport) {
    select {
        width: 100%;
    }
}

ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    line-height: 1.7em;
}

.app {
    .content, .content-minimal, .content-case, .content-homepage {
        margin-left: 0.7em;
        margin-right: 0.7em;
        margin-top: 3em;
    }

    .content, .content-homepage {
        margin-bottom: 6.5em;
    }

    .content-minimal {
        margin-bottom: 1em;
    }

    .content-case {
        margin-bottom: 7.5em;
    }
}

@media screen and (min-width: $minimum-viewport) {
    .app {
        .content, .content-minimal, .content-case, .content-homepage {
            margin-bottom: 0.7em;
            margin-left: calc(50vw - (26em / 2));
            max-width: 26em;
        }

        .content, .content-minimal, .content-case {
            margin-top: 4.5em;
        }
        
        .content-case {
            margin-top: 6.5em;
        }
    }
    
}

.button-container {
    display: flex;
    justify-content: center;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 999;
    
    .modal-main {
        position:fixed;
        color: var(--text-primary);
        background: var(--background-primary);
        width: 80%;
        max-width: 24.1em;
        padding: 1em;
        height: auto;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        border-radius: 1em;
        text-align: center;

        span {
            display: inline-block;
        }

        &-animation {
            width: 50%;

            .clear {
                position: absolute;
                top: 0.3em;
                right: 0.3em;

                svg {
                    height: 1.5em;
                    width: auto;
                }
            }
        }

        .animation {
            height: auto;
            width: 60%;
        }

        .button-container {
            column-gap: 1em;

            button {
                padding: 1em 15%;
            }
        }

        .picture-preview {
            max-height: 46vh;
        }
    }
}

@media screen and (min-width: $minimum-viewport) {
    .modal {        
        .modal-main {
            width: 50%;
        }
    }
}

.update-modal-container {
    justify-content: center;
    
    .update-modal {
        display: flex;
        position: fixed;
        background: var(--action-primary);
        color: var(--text-primary);
        width: 85%;
        padding: 1em;
        height: 1em;
        top: 9%;
        border-radius: 1em;
        border: black solid 1px;
        text-align: center;
        justify-content: center;
        align-items: center;
        animation: fadeIn 1s ease-in both;
        z-index: 1;

        @media screen and (min-width: $minimum-viewport) {
            width: 40%;
            max-width: 24em;
        }

        @keyframes fadeIn {
            from {
                opacity: 0;
                transform: translate3d(0, -30%, 0);
            }
            to {
                opacity: 1;
                transform: translate3d(0, 0, 0);
            }
        }

        span {
            position: absolute;
            top: 0.4em;
            right: 0.6em;
            margin: 0 0 0.5em 0.5em;

            i {
                height: 1em;
            }
        }

        p {
            margin: 0 1em;
            line-height: 1.1em;
        }
    }
}

.half-page {
    z-index: -1;
    position: absolute;
    top: 0;
    height: 100vh;
    width: 100vw;
    background-color: var(--background-tertiary);

    .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 50vh;
        position: absolute;
        bottom: 6.5rem;
        border-radius: 1.75em 1.75em 0 0;
        background-color: var(--background-primary);
        margin: 0;
        padding: 1em;
        width: calc(100% - 2em);
    }
}

@media screen and (min-width: $minimum-viewport) {
    .half-page {
        display: flex;
        justify-content: center;
        .content {
            height: 45%;
            width: 50%;
            bottom: 22%;
            border-radius: 1.75em 1.75em;

            h1 {
                margin-left: 0.4em;
            }

            b {
                margin-left: 1em;
            }
        }
    }
}

.search-container {
    position: relative;
    display: flex;
    margin: 0 0 1em 0;

    .search {
        padding-left: 0.5rem;
        border-radius: 0.3em;
        border: var(--color-light-grey-dark) solid 0.1rem;
        height: 1.7em;
    }

    .clear {
        cursor: pointer;
        position: absolute;
        top: 0.2em;
        right: 0.3em;

        svg {
            height: 1.75em;
            width: 1.75em;
            fill: var(--text-primary);
        }
    }
}

.error {
    &-message {
        color: var(--error);
        min-height: 1em;
        font-size: 1.2rem;
        display: inline-block;
    }

    input, select, textarea {
        border-color: var(--error);
    }
    
}

.clickable{
    cursor: pointer;
}

@keyframes rotation {
    0% { 
        transform: rotate(0deg);
        animation-timing-function: cubic-bezier(0.5856,0.0703,0.4143,0.9297);
    }
    100% { transform: rotate(360deg) }
}
@keyframes fadein {
    0% { opacity: 0}
    100% { opacity: 1}
}
.light {
    .loader {
        background-color: var(--color-light-grey-dark);
        .spinner {
            border-color: var(--background-primary);
            border-bottom-color: var(--accent);
        }
    }
}
.dark { 
    .loader {
        background-color: var(--black-darker);
        .spinner {
            border-color: var(--black-lighter);
            border-bottom-color: var(--accent);
        }
    }
}
.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    animation: fadein 0.5s ease-in-out;
    
    .spinner {
        width : 2em;
        height: 2em;
        border: 5px solid;
        border-radius: 50%;
        display: inline-block;
        animation: rotation 1s linear infinite;
    }

    &-section {
        height: 100%;
        min-height: 4em;
    }

    &-page {
        height: 100vh;
    }
}

.back-container {
    position: relative;
    margin-top: 1em;
    margin-bottom: -1em;

    .icon {
        svg {
            fill: var(--icon);
            cursor: pointer;
        }
    }
}

@media screen and (min-width: $minimum-viewport) {
    .back-container {
        position: fixed;
        display: flex;
        height: 4.5rem;
        width: 7.4rem;
        margin: 0;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        z-index: 1;

        .icon {
            svg {
                fill: var(--nav-fill);
            }
        }
    }
}

.pager {
    width: 100%;
    min-height: 2em;
    margin: 1.5em 0;
    display: flex;
    justify-content: space-evenly;
    user-select: none;

    .prev, .next {
        text-decoration: underline;
    }
    .disabled {
        text-decoration: none;
    }

    span {
        min-width: 1.5em;
        height: 1.5em;
        text-align: center;
    }

    .active {
        background-color: var(--accent);
        color: var(--text-accent);
        border-radius: 1.5em;
    }
}

.homepage {
    &-header {
        background: transparent;
        position: sticky;
        height: 0;
        top: 0;
        z-index: 1;

        @media screen and (min-width: $minimum-viewport) {
            position: relative;
            z-index: 0;
        }
        .svg {
            position: absolute;
            width: 0;
            height: 0;
        }

        .clipped {
            background-color: var(--background-tertiary);
            background-size: cover;
            background-position: center top;
            width: 100%;
            height: 9rem;
            -webkit-clip-path: url(#homepage-header-path);
            clip-path: url(#homepage-header-path);

            @media screen and (min-width: $minimum-viewport) {
                background-position-y: -3em;
            }
        }
    }
}

.user-portrait {
    position: sticky;
    display: flex;
    top: 2.8em;
    width: 100%;
    flex-direction: column;
    align-items: center;
    z-index: 1;

    .portrait {
        width: 3em;
        height: 3em;
        border-radius: 3em;
        display: inline-block;
        background-color: var(--color-yellow);
        text-align: center;
        vertical-align: middle;
        bottom: 0.75em;
        position: relative;
        background-size: cover;
        border: 0.15em solid var(--color-yellow);
    }
}

@media screen and (min-width: $minimum-viewport) {
    .user-portrait {
        position: relative;
        z-index: 0;
    }
}

.events {
    &-list {
        margin: 1em 0;

        .text {
            font-size: min(5vw, 1.4rem);
        }
        li {
            padding-bottom: 0.5em;
            &:not(:last-child){
                margin-bottom: 1em;
                border-bottom: 1px solid var(--action-secondary);
            }
        }
        .timestamp {
            width: 100%;
            display: inline-block;
            text-align: left;
            font-size: 12pt;
        }

        .event-notif {
            display: flex;
            gap: 0.5em;

            p {
                margin-top: 0;
            }

            i {
                height: 32px;
                width: 32px;
            }
        }
    }
}

.animation-container {
    display: flex;
    justify-content: center;
    border: 2px solid var(--color-light-grey-dark);
    border-radius: 1.5em;
    background-color: var(--color-white);
    
    .animation {
        height: 10em;
        width: auto;
    }
}

.icon {
    height: 1.9rem;
    width: auto;

    &-smaller {
        height: 1.4rem;
    }

    &-close {
        svg {
            fill: var(--icon) !important;
        }
    }

    svg {
        height: inherit;
        width: inherit;
        fill: var(--color-white);
    }

    &-stuff {
        .cls-1, .cls-2, .cls-3 {
            stroke: var(--color-black);
            stroke-miterlimit: 10;
          }
    
          .cls-4 {
            fill: var(--color-white);
          }
    
          .cls-2 {
            fill: var(--color-black);
          }
    
          .cls-3 {
            stroke-width: 2px;
          }
    }
}

.search-add-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1em;

    svg {
        fill: var(--color-black);
    }

    .search-container {
        margin: 0;

    }

    button {
        width: 10em;
        gap: 0.3em;
        margin: 0;
        margin-left: 0.5em;
    }
}

.loader-button {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    transition: all 0.5s;
    transition-timing-function: ease-in;
    height: 3.35em;

    span {
        display: flex;
        align-items: center;
        gap: 0.3em;
        transition: 0.75s all;
        white-space: nowrap;

        i {
            height: 1.5em;
        }
    }

    .loader {
        background-color: unset;
        transition: all 1s;

        .spinner {
            position: absolute;
            right: 1.3em;
            height: 0.75em;
            width: 0.75em;
            border-color: var(--color-black);
            border-bottom-color: transparent;
        }
    }

    &-nudge {
        span {
            margin-right: 3em;
        }
    }
}

.loader-button.button-tertiary {
    .loader {
        .spinner {
            border-color: var(--color-white) !important;
            border-bottom-color: transparent !important;
        }
    }
}