@use '../../../shared/layout/mystuff.scss';
@import "../../../shared/layout/components/variables";

.mystuff {
    .grid {
        height: calc(100vh - 22em) !important;
    }
}

.half-page {
    .mystuff-addpage {
        .stuff-add {
            margin-top: 0;
        }
    }
}

@media screen and (min-width: $minimum-viewport) {
    .mystuff {
        .grid {
            height: 65vh !important;
        }
    }
}

@media screen and (max-width: $minimum-viewport) {
    .half-page {
        .mystuff-addpage {
            height: 60vh;
            margin-bottom: 1.6em;
        }
    }
}