$minimum-viewport: 1024px;
$smallest-viewport: 385px;
$content-gutter: 1em;

@mixin badge {
    padding: 0.1em 0.5em;
    background: var(--color-red);
    color: var(--color-white);
    font-size: 14px;
    font-weight: 700;
    min-width: 0.6em;
    max-width: 1em;
    border-radius: 1em;
    
    &-greater {
        padding: 0.1em 0.3em;
    }
}
