.login {
    .logo-container {
        display: flex;
        justify-content: center;
        margin-bottom: 1em;
    
        img {
            height: 14.2em;
        }
    }
    form {
        margin: 1em 0;
    }
    a {
        text-decoration: underline;
        font-size: 14pt;
    }
    .error {
        color: var(--error);
        font-weight: bold;
    }

    .forgot-container {
        display: flex;
        flex-direction: column;
        gap: 0.5em;

        a {
            display: block;
            width: fit-content;
            padding: 0.4em;
        }
    }
    .staging-warning {
        background: var(--error);
        color: #fff;
        text-align: center;
        display: block;
        padding: 0.3em 0;
        border-radius: 0.3em;
        font-weight: 600;
    }
}