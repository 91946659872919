@import "../../shared/messages/messages.scss";
@import "../../shared/layout/components/variables";

.messages {
    .conversation-list {
        &-item {
            padding: 0.5em $content-gutter;

            .arrow-container {
                i {
                    right: 0.7em;
                }
            }
        }
    }
}