@import "../../shared/layout/components/variables";

.goals {
    .goals-container {
        margin-bottom: 12.5em !important;
    }

    .list {
        list-style-type: none;
        margin-bottom: 1em;
        
        .goal-container {
            display: flex;
            position: relative;
            align-items: center;
            padding: 1em 0;
            text-decoration: none;
            color: var(--text-primary);
            border-top: 1px solid var(--action-secondary);
            &:last-child{
                border-bottom: 1px solid var(--action-secondary);
            }

            svg {
                position: absolute;
                fill: var(--text-primary);
                top: calc(50% - 1.5em/2);
                right: 0;
                height: 1.5em;
                width: 1.5em;
            }
            .links {
                padding: 0 0.5em;
                text-decoration: underline;
            }
            .links:first-child{
                padding-left: 0;
            }

            span {
                font-weight: bold;
            }

            .title {
                font-size: 1.5rem;
            }

            .info {
                display: flex;
                flex-direction: column;
                row-gap: 0.4rem;

                .date {
                    font-size: 0.9rem;
                }
            }
        }
    }
    
    .add-goal {
        .input-section {
            form {
                textarea {
                    height: 10em;
                }
            }
        }
    }

    .buttons {
        display: flex;
        position: fixed;
        bottom: 5em;
        width: calc(100% - 0.7em*2);
        padding: 0.3em 0.7em 0.6em 0.7em;
        flex-direction: column;
        text-align: center;
        background: var(--background-primary);
        .button {
            margin-left: 0;
            margin-right: 0;
        }
    }
}

@media screen and (min-width: $minimum-viewport) {
    .goals {
        .goals-container {
            margin-bottom: 8em !important;
        }

        .buttons {
            bottom: 0;
            margin-left: calc(50vw - 13em);
            max-width: 26em;
        }
    }
}
