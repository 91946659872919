@use '../../shared/appointments/appointments.scss';
.next {
    h2, p {
        margin: 0; 
    }
}

.upcoming {
    li:not(:first-child) {
        margin-top: 1.1em;
    }
}
