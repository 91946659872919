@import "../layout/components/variables";

.homepage {
    .user-name {
        display: flex;
        justify-content: center;
        margin-top: 2.8em;

        .info {
            display: inline-block;
        }
    }
}