@import '../layout/components/variables';
.legal {
    a {
        text-decoration: underline;
    }

    ul {
        list-style-type: disc;
        margin-left: 1em;
    }
}