@import '../../shared/layout/components/variables';
@import "../../shared/homepage/homepage.scss";
$portrait-size: 3em;
$border-radius: 3em;

.homepage {
    .stuffbox-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(1, 1fr);
        margin: 0 0 1em 0;
        row-gap: 0.5em;
        justify-content: center;

        a {
            text-decoration: none;
        }

        .stuffbox {
            display: flex;
            align-items: center;
            flex-direction: row;
            padding: 0 0.5em;
            text-align: center;
            border-radius: 1em;
            position: relative;
            color: var(--color-black);
            gap: 1em;

            i {
                height: 2.5em;
                width: 2.5em;
                margin-left: 0.6em;
            }

            &-whoiam {
                background-color: var(--color-blue-light);
            }

            &-whoiwant {
                background-color: var(--color-green);
            }

            &-howiget {
                background-color: var(--color-purple);
                color: var(--color-white);
                fill: var(--color-white);
            }

            &-addmore {
                background-color: var(--color-yellow);
                
                i {
                    height: 2.2em;
                }
            }

            svg {
                width: inherit;
                height: inherit;
            }

            h2 {
                font-size: min(7vw, 2rem);
            }
        }
    }

    &-button-container {
        display: flex;
        flex-direction: column;

        a {
            font-size: min(4vw, 1rem);
            text-align: center;
        }
    }

    .notification-container {
        margin: 0 0 1em 0;

        .notification-header-container {
            display: flex;
            align-items: center;
            position: relative;
            height: 1em;
            margin: 1.5em 0;

            h2 {
                margin: 0 0.25rem 0 0;
                height: 1.5em;
            }

            .navBubble {
                @include badge;
            }
        }
    }

    .timeline-container {
        display: flex;
        justify-content: center;
    }
}