@import "../../shared/layout/components/variables";
$arrow-width: 0.8em;
$profile-size: 2.5em;
$message-padding: 0.5em;
$conversation-list-color: var(--text-primary);

.messages {
    .button {
        display: block;
        text-align: center;
        margin-left: 0;
        margin-right: 0;
    }

    .conversation-list {
        height: calc(100vh - 21.5em) !important;
        
        &-item {
            position: relative;
            padding: 0.5em 0 0 0;

            &-narrower {
                margin-left: 0;
            }

            b {
                text-decoration: underline;
                color: $conversation-list-color;
            }

            .preview {
                width: calc(100% - $arrow-width);
                display: inline-block;
                color: $conversation-list-color;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            i {
                svg {
                    fill: var(--icon); 
                }
            }

            .subject-body {
                width: calc(100% - 2.7em);

                .padlock-container {
                    display: inline;
                    margin-left: 0.2em;
    
                    i {
                        display: inherit;
                        height: 0.8em;
                    }
                }
            }

            .arrow-container {
                i {
                    position: absolute;
                    top: 1.2em;
                    right: 0;
                }
            }

            .timestamp {
                width: 100%;
                text-align: right;
                display: inline-block;
                font-size: 1rem;
                color: $conversation-list-color;
            }


            &:not(:last-child) {
                padding-bottom: 0.25em;
                border-bottom: var(--color-light-grey-dark) 0.05em solid;
            }
        }

        .unread {
            .preview {
                font-weight: bold;
            }
        }

        .old {
            background-color: var(--old-conversation);
            opacity: 75%;
        }
    }

    &-header {
        h1 {
            font-size: 2rem;
            font-weight: bold;
            margin-block: 0.83em 0.83em;
            margin-bottom: 1rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            height: 1.1em;
        }
    }

    &-list {
        height: calc(100vh - 17.5em) !important;

        div {
            overflow-x: hidden !important;
        }

        .item {
            display: grid;
            align-items: center;
            margin: 1em 0;
            position: relative;
        }

        .item:first-of-type {
            margin: 0 0;
            padding-top: 0.8em;
        }

        .profile {
            width: $profile-size;
            height: $profile-size;
            display: inline-block;
            border-radius: $profile-size;
            background-color: var(--color-yellow);
            border: 0.1em solid var(--color-yellow);
            background-size: cover;
            margin: auto;
        }

        .text {
            display: inline-block;
            word-wrap: break-word;
            border-radius: $message-padding;
            padding: $message-padding;
        }

        span {
            font-size: 1rem;
            width: 100%;
            display: block;
            color: var(--text-primary);

        }

        .mine {
            grid-template-columns: calc(100% - 3.75em) 2.7em;

            .text {
                grid-row: 1;
                grid-column: 1;
                background-color: var(--chat-self);
                color: var(--color-white);
                margin-right: 1em;
                text-align: right;
            }

            span {
                text-align: left;
            }

            .profile {
                grid-row: 1;
                grid-column: 2;
            }
        }

        .target {
            grid-template-columns: 2.7em calc(100% - 3.75em);

            .text {
                grid-row: 1;
                grid-column: 2;
                background-color: var(--chat-target);
                color: var(--color-black);
                margin-left: 1em;
                text-align: left;
            }

            span {
                text-align: right;
                grid-row: 2;
                grid-column: 2;
            }

            .profile {
                grid-row: 1;
                grid-column: 1;
            }
        }

        .closed {
            display: flex;

            .text {
                grid-row: 1;
                grid-column: 2;
                color: var(--text-primary);
                text-align: left;
            }

            span {
                text-align: right;
                grid-row: 2;
                grid-column: 2;
            }

            .profile {
                grid-row: 1;
                grid-column: 1;
            }
        }
    }

        &-add {
            display: flex;
            align-items: baseline;
            gap: 0.5em;
    
            input {
                width: 100%;
                font-size: 1em;
                height: 1.8em;
                padding: 0.4em;
            }

            button:disabled {
                background-color: var(--action-secondary);
                color: var(--action-text-primary);
            }
        }

    &-create {
        label {
            width: 100%;
            font-weight: bold;
            display: inline-block;

            &:not(:first-child) {
                padding-top: 1em;
            }
        }

        input,
        textarea {
            width: calc(100% - 0.4em);
        }

        textarea {
            height: 15rem;
            font-size: 20px;
            width: calc(100% - 0.5em);
        }

        .send {
            text-align: right;
            margin-bottom: 6em;
        }
    }

    @media screen and (display-mode: browser) and (orientation: portrait) and (max-width: 750px) {
        .conversation-list, &-list {
            height: calc(100vh - 21em) !important;
        }
    }
}

@media screen and (min-width: $minimum-viewport) {
    .messages {
        .conversation-list {
            height: calc(100vh - 17em) !important;
        }
        
        &-list {
            height: calc(100vh - 12em) !important;
        }
    
        &-add {
            form {
                input {
                    width: 90vw;
                }
            }
        }

        &-create {
            .send {
                text-align: right;
                margin-bottom: 0;
            }
        }
    }
}