.notifications {

    .icon {
        svg {
            fill: var(--icon);
        }
    }

    .button-container {
        flex-direction: row-reverse;

        button {
            padding: 0.3em 0.8em;  
        }
    }
}