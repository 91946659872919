@import "../../../shared/layout/components/variables";

.tasks {
    &-container {
        margin-bottom: 10em !important;
    }

    .date {
        background: var(--action-secondary);
        width: fit-content;
        padding: 0.25em 0.7em;
        border-radius: 0.3em;
        font-size: 1rem;
        font-weight: bold;
    }
    
    .list {
        list-style-type: none;
        align-items: stretch;
        margin: 0 0 1em 0;

        .list-container {
            display: flex;
            flex-direction: column;
            gap: 0.75em;
        }

        .task-container {
            display: grid;
            position: relative;
            grid-template-columns: auto 85%;
            align-items: center;

            .links {
                padding: 0 0.5em;
                text-decoration: underline;
            }
    
            .links:first-child {
                padding-left: 0.4em;
            }
    
            input[type="checkbox"] {
                height: 1.3em;
                width: 1.5em;
                justify-self: center;
            }
    
            label {
                min-height: 2em;
            }
        }

        b {
            font-size: 1.5rem;
            padding-left: 0.32em;
        }
    }

    .buttons {
        display: flex;
        position: fixed;
        bottom: 5em;
        width: calc(100% - 0.7em*2);
        padding: 0.3em 0.7em 0.6em 0.7em;
        flex-direction: column;
        text-align: center;
        background: var(--background-primary);

        .button {
            margin-left: 0;
            margin-right: 0;
        }
    }
}

@media screen and (min-width: $minimum-viewport) {
    .tasks {
        .buttons {
            bottom: 0;
            margin-left: calc(50vw - 13em);
            max-width: 26em;
        }
    }
}

@media screen and (max-width: $minimum-viewport) {
    .tasks {
        &-lists {
            margin-bottom: 10em !important;
        }
    }
}