$profile-size: 2.5em;
.timeline {

    &-item {
        display: flex;
        gap: 0.5em;
        margin-bottom: 0.5em;

        p {
            margin-top: 0.5em;
        }
    }

    .loader {
        min-height: 30em;
    }

    .profile {
        width: $profile-size;
        height: $profile-size;
        display: inline-block;
        border-radius: $profile-size;
        background-color: var(--color-yellow);
        border: 0.1em solid var(--color-yellow);
        background-size: cover;
    }
}