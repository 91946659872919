@use '../../shared/settings/Settings.scss';
.settings{
    ul{
        list-style: none;
        padding: 0;
    }
}

.profile {
    pre{
        font-family: manrope;
        min-height: 5em;
        margin: 0;
        padding: 0.25em;
    }

    .theme-selection{
        input {
            margin-left: 0;
        }
    }

    .bio {
        p {
            margin-top: 0;
        }
        h2 {
            margin-bottom: 0.3em;
        }
    }
}