@import '../../shared/layout/components/variables';

.cases {
    .box {
        &-row {
            display: grid;
            grid-template-columns: calc(100% - 3.7em) 2.4em 1.3em;
            align-items: center;

            div {
                text-decoration: underline;
            }

            .controls {
                text-align: right;
                justify-self: center
            }

            .notification {
                @include badge;
                justify-self: center;
                height: 1.5em;
                text-align: center;
                line-height: 1.6em;
            }


            &:not(:first-child) {
                padding: 0.2em 0 0 0;
            }

            &:not(:last-child) {
                border-bottom: var(--color-light-grey-dark) 0.08em solid;
                padding: 0.1em 0;
            }

            @media screen and (min-width: $minimum-viewport) {
                &:not(:first-child) {
                    padding: 0.4em 0 0 0;
                }

                &:not(:last-child) {
                    padding: 0.2em 0;
                }
            }
        }
    }
}