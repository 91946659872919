@import "../../shared/layout/components/variables";

.overlay {
    height: 100%;
    position: fixed;
    top: 0em;
    right: 0em;
    background-color: var(--background-secondary);
    overflow: hidden;
    transition: 0.1s;
    z-index: 2;

    .content {
        position: relative;
        top: 10%;
        left: 10%;
        width: 100%;
        text-align: left;
        margin-top: 0.5em !important;

        title {
            font-size: 2.5em;
            font-weight: 900;
            display: block;
            margin: 0 0 0.7em 0;
        }

        &-item {
            margin: 0 0 0.4em 0;
            font-size: 1.5em;
            font-weight: 400;
            display: block;
            transition: 0.3s;
            color: var(--text-primary);
            text-decoration: none;
        }

        &-item:hover {
            text-decoration: underline;
        }

    }

    .closebtn {
        display: grid;
        position: absolute;
        margin: -0.75em 0 0 0;
        top: 0.8em;
        right: 0.05em;
        font-size: 1.6em;
        cursor: pointer;
    }
}

.header {
    display: flex;
    position: fixed;
    height: 3.4rem;
    font-size: 1.4rem;
    align-items: center;
    top: 0;
    right: 0;
    padding-right: 0.5em;
    padding-left: 0.7em;
    border-bottom-left-radius: 1.5em;
    background-color: var(--nav-background);
    z-index: 2;

    .focus::before {
        transform: translateX(-50%);
        border-radius: 100%;
        position: absolute;
        background: var(--color-white);
        bottom: 2px;
        height: 2px;
        content: "";
        width: 10px;
        left: 50%;
    }

    .item {
        position: relative;
        font-size: 1em;
        margin-top: 0.3em;

        svg {
            fill: var(--nav-fill);
            height: 1.5em;
            width: auto;
        }

        .notifContainer {
            position: relative;

            .badge {
                @include badge;
                position: absolute;
                bottom: 1.7em;
                left: -0.4em;
            }

            .empty {
                margin-left: 0;
            }
        }
    }

    .item:not(:last-child) {
        margin-right: 1rem;
    }
}

.nav-menu {
    background: var(--nav-background);
    display: grid;
    position: fixed;
    bottom: 0;
    height: 6.5em;
    width: 100%;
    font-size: 1rem;
    z-index: 1;

    .case-banner {
        position: absolute;
        height: 2em;
        width: 100%;
        background: var(--color-purple);
        color: var(--color-white);
        top: -2em;
        text-align: center;
        font-weight: bold;
        line-height: 2em;
    }

    .item {
        display: grid;
        grid-template-rows: 30% 30%;
        align-content: center;
        align-items: center;
        justify-items: center;
        justify-content: center;
        background-color: var(--nav-background);
        position: relative;
        height: 100%;
        width: 100%;
        text-decoration: none;
        color: var(--nav-fill);

        svg {
            fill: var(--nav-fill);
        }


        .text {
            display: grid;
            text-align: center;
            font-size: 0.8em;
        }

        .focus::before {
            transform: translateX(-50%);
            border-radius: 100%;
            position: absolute;
            background: var(--color-white);
            bottom: 15px;
            height: 5px;
            content: '';
            width: 5px;
            left: 50%;
        }

        .notifContainer {
            position: relative;

            .badge {
                @include badge;
                position: absolute;
                bottom: 1.8em;
                left: 1.4em;
            }

            .empty {
                margin-left: 0;
            }
        }
    }
}

@media screen and (min-width: $minimum-viewport) {
    .overlay {
        .content {
            text-align: center;
            left: 0%;

            title {
                font-size: 3em;

                margin: 0 0 0.5em 0;
            }

            &-item {
                font-size: 4.5vh;
            }
        }

        .closebtn {
            top: 1em;
            right: 0.05em;
            padding: 1em;
        }
    }

    .header {
        height: 4.47rem;
        align-items: center;
        padding-right: 0.5em;
        background-color: var(--nav-background);
    }

    .nav-menu {
        display: flex;
        justify-content: center;
        top: 0;
        height: 4.47rem;
        gap: 1.8em;

        & + div {
            margin-top: 4.47rem;
        }

        .case-banner {
            top: 4.47rem;
        }

        .item {
            display: flex;
            gap: 1em;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
            padding: 0;
            background-color: inherit;
            width: initial;

            .notifContainer {
                display: flex;
                align-items: center;
                height: 1.5em;
                width: 1.5em;

                .badge {
                    bottom: 1.1em;
                    left: 1.2em;
                }
            }

            .text {
                text-align: left;
                padding: 0;
                display: inline;
                font-size: 1em;
            }

            .focus {
                text-decoration: underline;
                text-underline-offset: 0.5em;
            }

            .focus::before {
                height: 0px;
                width: 0px;
            }
        }
    }

    .nav-case {
        padding-bottom: 2em;
        & + div {
            margin-top: 6.47rem;
        }
    }
}